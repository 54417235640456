<template>
  <b-container fluid>
    <b-row class="justify-content-center custom-text-title mb-4">
      <p class="w-100 p3 color-green-2 justify-content-center text-center">
        Os contatos de referência permitem que possamos contatar alguém de sua confiança para fornecer ou receber informações sobre a Cooperativa, pacientes e os direitos e deveres do Cooperado.
      </p>
      <p class="w-100 p3 color-green-2 justify-content-center text-center">
        <strong>Você pode alterar essas informações a qualquer momento.</strong>
      </p>
    </b-row>
    <div v-show="!this.loading && !this.inEditing">
      <AccordionList
        titleKeyName="description"
        :items="this.contacts"
        :itemsFields="this.tableFields"
        :itemsFieldsFunction="this.getItemsFields"
        :onEdit="this.onEdit"
        :onDelete="this.onDelete"
        msgToggleEdit="Editar Contato"
        msgToggleDelete="Excluir Contato"
      />
    </div>
    <div v-show="this.loading">
      <b-card>
        <b-skeleton type="AccordionList" width="100%"></b-skeleton>
        <b-skeleton-table
          :rows="tableFields.length"
          :columns="2"
          :hide-header="true"
        ></b-skeleton-table>
      </b-card>
      <p />
      <b-card>
        <b-skeleton type="AccordionList" width="100%"></b-skeleton>
        <b-skeleton-table
          :rows="tableFields.length"
          :columns="2"
          :hide-header="true"
        ></b-skeleton-table>
      </b-card>
    </div>
    <div class="mt-5" v-show="!this.loading && this.inEditing">
      <NewContactsEmergencyForm
        v-if="this.inEditing"
        :onSubmit="this.onSubmitEdit"
        :newRegister="this.newRegister"
        :onClose="this.onClose"
        :loadedFormData="this.loadedFormData"
      />
    </div>

    <div class="mt-5" v-show="!this.loading && !this.inEditing">
      <b-button
        class="my-3 mr-3"
        variant="outline-primary"
        @click="this.onClickAddNewContact"
      >
      Adicionar contato de referência
      </b-button>
    </div>
    <success-modal
      ref="success-modal"
      :description="this.msgSuccess"
      buttonText="Ok, entendi!"
    />
    <attention-modal
      modalId="delete-attention-modal"
      title="Atenção!"
      submitButtonText="Sim"
      cancelButtonText="Não"
      ref="attention-modal"
      :onAction="this.onDeleteComfirm"
    >
      <template slot="description">
        <div class="w-100 mb-5">
          <div class="d-flex align-content-center flex-wrap">
            <p class="w-100 p1 custom-text-align mb-2">Tem certeza que deseja excluir este contato?</p>
          </div>
        </div>
      </template>
    </attention-modal>
  </b-container>
</template>

<script>
import AccordionList from "../../../../components/accordion-list/AccordionList.vue";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import AttentionModal from "../../../../components/base-modals/AttentionModal.vue";

import {
  getContactsEmergency,
  updateContactEmergency,
  addContactEmergency,
  deleteContactEmergency
} from "../../../../services/contactsEmergency/contactsEmergencyService";
import { inject } from "@vue/composition-api";

import NewContactsEmergencyForm from "../forms/contactsEmergency/NewContactsEmergencyForm.vue";

const tableFields = [
  { key: "description", label: "Tipo", tdClass: "text-right custom-table" },
  { key: "name", label: "NOME", tdClass: "text-right" },
  { key: "cellPhone", label: "CELULAR", tdClass: "text-right" },
  { key: "telephone", label: "TELEFONE", tdClass: "text-right" },
  { key: "email", label: "E-MAIL", tdClass: "text-right" },
];

const ContactsEmergenciesInformation = {
  name: "contacts-emergencies-information",
  components: {
    AccordionList,
    SuccessModal,
    AttentionModal,
    NewContactsEmergencyForm
  },

  setup() {
    const globalLoading = inject("globalLoading");
    return { tableFields, globalLoading };
  },
  mounted() {
    this.getContactsEmergencyInfo();
  },
  data() {
    return {
      contacts: [],
      loading: true,
      inEditing: false,
      loadedFormData: null,
      msgSuccess: "",
      newRegister: false,
    };
  },
  methods: {
    getItemsFields(item) {
      let tableFields = [...this.tableFields];
      if (item.type == "FAMILY") {
        tableFields.splice(1, 0, { key: "degreeParent", label: "GRAU DE PARENTESCO", tdClass: "text-right" })
      }
      
      if (item.type == "OTHERS") {
        tableFields.splice(1, 0, { key: "specify", label: "ESPECIFIÇÃO DO CONTATO", tdClass: "text-right" })
      }

      return tableFields;
    },
    onClose() {
      this.inEditing = false;
    },
    onEdit(item) {
      this.loadedFormData = item;
      this.inEditing = true;
    },
    showSuccessModal() {
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
    async getContactsEmergencyInfo() {
      try {
        const { crm } = this.$store.getters.getUserInformation;
        this.loading = true;
        this.contacts = await getContactsEmergency(crm);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getContactsEmergencyInfo", error);
      }
    },
    async onClickAddNewContact() {
      this.newRegister = true;
      this.loadedFormData = {};
      this.inEditing = true;
    },
    async onDeleteComfirm(confirmed) {
      if (!confirmed) return;
      try {
        this.globalLoading = true;
        this.msgSuccess = "Contato excluído!";
        await deleteContactEmergency(this.itemToDelete.id);
        this.globalLoading = false;
        this.showSuccessModal();
        this.getContactsEmergencyInfo();
      } catch (error) {
        this.globalLoading = false;
        console.error("deleteContactEmergency", error);
      }
    },
    async onDelete(item) {
      this.itemToDelete = item;
      const modal = this.$refs["attention-modal"].$children[0];
      modal.show();
    },
    async onSubmitEdit(formData) {
      this.globalLoading = true;
      try {
        if (this.newRegister == true) {
          this.msgSuccess = "Novo contato cadastrado.";
          await addContactEmergency(formData);
        } else {
          this.msgSuccess = "Sua informações foram alteradas.";
          await updateContactEmergency(formData);
        }
        this.showSuccessModal();
        this.onClose();
        this.getContactsEmergencyInfo();
        this.newRegister = false;
        this.globalLoading = false;
      } catch (err) {
        console.error(err);
        this.newRegister = false;
        this.globalLoading = false;
      }
    },
  },
};

export default ContactsEmergenciesInformation;
</script>

<style lang="scss">
.addresses-container td::before {
  text-align: left !important;
}

.addresses-container td:first-child {
  border-color: #eff2f5;
}

.custom-text-align {
  text-align: center;
}

.custom-text-title {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
</style>
