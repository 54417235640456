import { formatFone } from "../../utils/format/formtUtils";
import { TypeContactsEmergency } from "../../static-data/TypeContactsEmergency";

function getFoneFormatado(value) {
  
  if(value && value.indexOf('(') != -1)
    return value;

  const fone = formatFone(value);
  
  return fone === null || fone === undefined ? "" : fone;
}

export function mapperToContactEmergencyRequest(formData) {
  return {
    "id": formData?.id,
    "type": formData.type,
    "name": formData.name,
    "cellPhone": getFoneFormatado(formData.cellPhone),
    "telephone": getFoneFormatado(formData.telephone),
    "email": formData.email,
    "degreeParent": formData.degreeParent,
    "specify": formData.specify,
  }
}


export function mapperToReponseContactEmergency(resp) {

  resp.forEach((element) => {
    const typeContact = TypeContactsEmergency.filter(contact => (contact.enum === element.type));
    if (typeContact.length > 0) {
      element.description = typeContact[0].title;
    }
  });
  return resp;
}
