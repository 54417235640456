<template>
  <div>
    <b-card border-variant="light" class="custom-card my-4">
      <p
        class="P21"
        v-if="this.form.typeContact"
        v-text="this.form.typeContact"
      ></p>
      <p class="P21" v-if="!this.form.typeContact">NOVO CONTATO DE REFERÊNCIA</p>
      <b-form id="alter-form" class="alter-form">
        <b-form-row>
          <b-col>
            Tipo
          </b-col>
          <b-col>
            <b-form-select
              v-model.lazy.trim="form.type"
              :state="validateField('type')"
              :options="contactTypesOptions"
            />
          </b-col>
        </b-form-row>

        <b-form-row v-if="form.type == 'FAMILY'">
          <b-col>
            GRAU DE PARENTESCO
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.degreeParent"
              placeholder="Digite"
              :state="validateField('degreeParent')"
            />
          </b-col>
        </b-form-row>

        <b-form-row v-if="form.type == 'OTHERS'">
          <b-col>
            ESPECIFIÇÃO DO CONTATO
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.specify"
              placeholder="Digite"
              :state="validateField('specify')"
            />
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            Nome
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.name"
              :state="validateField('name')"
              placeholder="Digite"
            />
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            Celular
          </b-col>
          <b-col>
            <CustomMaskInput
              :initialValue="form.cellPhone"
              placeholder="Digite"
              :options="numberOptionsCell"
              :state="validateField('cellPhone')"
            />
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            TELEFONE
          </b-col>
          <b-col>
            <CustomMaskInput
              :initialValue="form.telephone"
              :options="numberOptionsPhone"
              placeholder="Digite"
            />
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            E-mail
          </b-col>
          <b-col>
            <b-form-input
              v-model.lazy.trim="form.email"
              placeholder="Digite"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-card>
    <b-row class="my-5">
      <b-button
        variant="outline-primary"
        @click="this.onClosetHandler"
        class="ml-4"
      >
        Cancelar
      </b-button>

      <b-button
        form="alter-form"
        type="button"
        class="ml-4"
        variant="primary"
        id="submitButtonText"
        :disabled="this.$store.getters.isAdmin"
        @click="this.onSubmitHandler"
      >
        Salvar
      </b-button>
    </b-row>
    <attention-modal
      modalId="alter-attention-modal"
      title="Atenção!"
      submitButtonText="Sim, cancelar"
      cancelButtonText="Voltar para edição"
      ref="attention-cancel-modal"
      :onAction="this.onCancelComfirm"
    >
      <template slot="description">
        <div class="w-100 mb-5">
          <div class="d-flex align-content-center flex-wrap">
            <p class="w-100 p1 custom-text-align mb-2">Algumas informações incluídas serão perdidas com esta ação.</p>
            <p class="w-100 p1 custom-text-align">Deseja mesmo cancelar a edição das informações?</p>
          </div>
        </div>
        
      </template>
    </attention-modal>
  </div>
</template>

<script>
import { TypeContactsEmergency } from "../../../../../static-data/TypeContactsEmergency";
import CustomMaskInput from "../../../../../components/forms/custom-mask-input/CustomMaskInput.vue";
import AttentionModal from "../../../../../components/base-modals/AttentionModal.vue";

const InvalidEventHandler = () => {
  console.warn("Invalid onChange handler");
};

const OptionsNumber = {
  numericOnly: true,
  delimiters: ["(", ") ", " ", "-"],
  blocks: [0, 2, 9],
  onValueChanged: InvalidEventHandler,
};

const DefaultForm = {
  type: null,
  name: null,
  cellPhone: null,
  telephone: null,
  email: null,
  degreeParent: null,
  specify: null,
};

export default {
  props: [
    "loadedFormData",
    "newRegister",
    "onClose",
    "onSubmit",
  ],
  components: { CustomMaskInput, AttentionModal },
  mounted() {
    this.initForm();
    this.initTypeContact();
  },
  data() {
    return {
      isNewRegister: false,
      formSubmited: false,
      form: {
        ...DefaultForm,
      },
      validations: {},
      contactTypesOptions: [],
      numberOptionsPhone: {
        ...OptionsNumber,
        onValueChanged: (e) => this.onChangeNumber(e, "telephone"),
      },
      numberOptionsCell: {
        ...OptionsNumber,
        onValueChanged: (e) => this.onChangeNumber(e, "cellPhone"),
      },
      selectedOption: null,
      selectOptions: [],
    };
  },
  methods: {
    async onCancelComfirm(confirmed) {
      if (!confirmed) return;

      if (this.$props.onClose) {
        this.$props.onClose();
      }
    },
    async onCancelAlter(item) {
      this.itemToDelete = item;
      const modal = this.$refs["attention-cancel-modal"].$children[0];
      modal.show();
    },
    formNotTouched() {
      return (
        this.form.type == this.form.originalData?.type &&
        this.form.name == this.form.originalData?.name &&
        this.onlyNumbers(this.form.cellPhone) == this.onlyNumbers(this.form.originalData?.cellPhone) &&
        this.onlyNumbers(this.form.telephone) == this.onlyNumbers(this.form.originalData?.telephone) &&
        this.form.email == this.form.originalData?.email &&
        this.form.degreeParent == this.form.originalData?.degreeParent &&
        this.form.specify == this.form.originalData?.specify
      );
    },
    initForm() {
      const loadedFormData = this.$props.loadedFormData;
      this.isNewRegister = this.$props.newRegister;
      if (loadedFormData) {
        this.form = {
          ...this.form,
          ...loadedFormData,
          formSelected: false,
        };
        this.form.originalData = { ...this.form };
      }
    },
    async initTypeContact() {
        this.contactTypesOptions = this.buildSelectTypeContactOptions(TypeContactsEmergency);
    },
    buildSelectTypeContactOptions(dataOptions) {
      return [
        { value: null, text: "Selecione um tipo de contato", disabled: true },
        ...dataOptions.map((formData) => ({
          value: formData.enum,
          text: formData.title,
        })),
      ];
    },
    onChangeNumber(e, value) {
      this.form[value] = e.target.rawValue;
    },
    validateField(name) {
      let value = this.form[name];

      let validation = !!value;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return !!value;
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    onSubmitHandler() {
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        this.$props.onSubmit(this.form);
      }
    },
    onClosetHandler() {
      let canClose = true;
      if (!this.isNewRegister && !this.formNotTouched()) {
        this.onCancelAlter();
        canClose = false;
      } 

      if (this.$props.onClose && canClose) {
        this.$props.onClose();
      }
    },
    onlyNumbers(value) {
      if (value == null || value == undefined) return value;
      return value.replace(/\D/g, '');
    }
  },
};
</script>

<style lang="scss" scoped>
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

.P21 {
  color: var(--primary);
  font-size: multiplier-default-font-size(3); // 16
  margin-left: 1.5rem;
}
input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}
.p4 {
  font-size: 1.6rem;
}
.teste {
  background-color: aqua;
}

.custom-text-align {
  text-align: center;
}
</style>
