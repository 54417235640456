import axios from "../http-client/axios";
import { SCORPIONS_URL } from "../urls/base-urls";
import { mapperToContactEmergencyRequest, mapperToReponseContactEmergency } from "./contacts-emergency-mapper";

export async function getContactsEmergency(crm) {
  const resp = await axios.post(
     `${SCORPIONS_URL}/contacts-emergency/find-contacts`,
       {crm: crm}
   );

   return mapperToReponseContactEmergency(resp.data);
}

export async function addContactEmergency(formData) {
  const resp = await axios.post(
    `${SCORPIONS_URL}/contacts-emergency/create`,
    mapperToContactEmergencyRequest(formData)
  );
  return resp.data;
}

export async function updateContactEmergency(formData) {
  const request = mapperToContactEmergencyRequest(formData);

  const resp = await axios({
    url: `${SCORPIONS_URL}/contacts-emergency`,
    method: "put",
    data: request,
  });

  return resp.data;
}


export async function deleteContactEmergency(id) {
  const resp = await axios({
    url: `${SCORPIONS_URL}/contacts-emergency?id=${id}`,
    method: "delete",
    data: {},
    headers: { "content-type": "application/json" },
  });

  return resp.data;
}